<template>
  <div>
    <p class="has-text-weight-bold is-size-4 profile-title">
      {{ $t("af:profile.title") }}
    </p>

    <div class="af-card--user">
      <template v-if="creditEnabled">
        <p class="has-text-weight-bold">
          {{ $t("af:profile.application_mode.title") }}
        </p>

        <p class="has-text-grey-icon">
          {{ $t("af:profile.application_mode.subtitle") }}
        </p>

        <div class="setting-tile mx-0 my-2">
          <b-select
            :value="customer.currentApplicationMode"
            @input="changeApplicationMode"
          >
            <option :value="APPLICATION_MODE.LOAN">
              {{ $t("af:profile.application_mode.loan") }}
            </option>
            <option :value="APPLICATION_MODE.MORTGAGE">
              {{ $t("af:profile.application_mode.mortgage") }}
            </option>
          </b-select>
        </div>
      </template>

      <p class="has-text-weight-bold">
        {{ $t("af:profile.personal_data.title") }}
      </p>

      <p class="has-text-grey-icon">
        {{ $t("af:profile.personal_data.subtitle") }}
      </p>

      <div class="columns setting-tile mx-0 my-2">
        <div class="column is-one-quarter">
          {{ $t("af:profile.name.label") }}
        </div>
        <div class="column">
          <span class="has-text-weight-medium">{{
            customer.lastName
              ? customer.lastName + " " + customer.firstName
              : "-"
          }}</span>
        </div>
        <div class="column is-one-quarter">
          <UserEditNameModal
            :last-name="customer.lastName"
            :first-name="customer.firstName"
            @save="fetchCustomer"
          />
        </div>
      </div>

      <div class="columns setting-tile mx-0 my-2">
        <div class="column is-one-quarter">
          {{ $t("af:profile.email.label") }}
        </div>
        <div class="column">
          <span class="has-text-weight-medium" v-if="customer.email"
            >{{ customer.email }}
            <span
              v-if="customer.emailConfirmed"
              class="is-inline-flex is-align-items-center"
            >
              <b-icon
                icon-pack="fa"
                icon="check-circle"
                class="ml-4 is-blue-icon"
              ></b-icon>
              <label class="confirmation-font ml-1">
                {{ $t("af:profile.email.confirmed") }}</label
              >
            </span>
            <span v-else class="is-inline-flex is-align-items-center">
              <b-icon
                icon-pack="fa"
                icon="exclamation-circle"
                class="ml-4 is-yellow-icon"
              ></b-icon>
              <label class="confirmation-font ml-1">{{
                $t("af:profile.email.not_confirmed")
              }}</label>
            </span>
          </span>
          <span v-else class="is-inline-flex is-align-items-center">
            {{ $t("af:profile.email.email_placeholder") }}
            <b-icon
              icon-pack="fa"
              icon="exclamation-triangle"
              class="ml-4 is-red-icon"
            ></b-icon>
            <label class="confirmation-font ml-1">{{
              $t("af:profile.email.email_not_exists")
            }}</label>
          </span>
        </div>
        <div class="column is-one-quarter">
          <UserEditEmailModal
            :current-email="customer.email"
            :is-email-confirmed="customer.emailConfirmed"
            @save="fetchCustomer"
          />
        </div>
      </div>

      <div class="columns setting-tile mx-0 my-2">
        <div class="column is-one-quarter">
          {{ $t("af:profile.password.label") }}
        </div>
        <div class="column">
          <i>{{ $t("af:profile.password.cannot_be_shown") }}</i>
        </div>
        <div class="column is-one-quarter">
          <UserEditPasswordModal />
        </div>
      </div>

      <div class="columns setting-tile mx-0 my-2">
        <div class="column is-one-quarter">
          {{ $t("af:profile.phone.label") }}
        </div>
        <div class="column">
          <span
            class="has-text-weight-medium is-inline-flex is-align-items-center"
            >{{ customer.phone | VMask("+## ## ### ####") }}
            <b-icon
              icon-pack="fa"
              icon="check-circle"
              class="ml-4 is-blue-icon"
            ></b-icon>
            <label class="confirmation-font ml-1">
              {{ $t("af:profile.phone.confirmed") }}</label
            >
          </span>
        </div>
        <div class="column is-one-quarter">
          <UserEditPhoneModal :phone="customer.phone" @save="fetchCustomer" />
        </div>
      </div>

      <UserStatements></UserStatements>
      <UserDynamicStatements></UserDynamicStatements>
      <UserDocuments></UserDocuments>

      <p class="has-text-weight-bold mt-6">
        {{ $t("af:profile.delete.title") }}
      </p>

      <p class="has-text-grey-icon">
        {{ $t("af:profile.delete.subtitle") }}
      </p>

      <p class="has-text-weight-medium">
        {{ $t("af:profile.delete.button.delete.label") }}
        <a @click="onDelete">{{
          $t("af:profile.delete.button.delete.text")
        }}</a>
      </p>
      <p class="has-text-weight-medium">
        {{ $t("af:profile.delete.button.gdpr.label") }}
        <a @click="onGdpr">{{ $t("af:profile.delete.button.gdpr.text") }}</a>
      </p>

      <div class="mt-6">
        <b-button icon-left="power-off" type="is-danger" @click="logout">{{
          $t("af:profile.button.logout")
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { APPLICATION_MODE } from "@/utils/const";
import { creditEnabled } from "@/utils/util";

export default {
  name: "UserProfile",
  title: "af:page.user_profile.title",
  components: {
    UserStatements: () => import("@/components/user/UserStatements.vue"),
    UserDynamicStatements: () =>
      import("@/components/user/UserDynamicStatements.vue"),
    UserDocuments: () => import("@/components/user/UserDocuments.vue"),
    UserEditNameModal: () => import("@/components/user/UserEditNameModal.vue"),
    UserEditPasswordModal: () =>
      import("@/components/user/UserEditPasswordModal.vue"),
    UserEditPhoneModal: () =>
      import("@/components/user/UserEditPhoneModal.vue"),
    UserEditEmailModal: () =>
      import("@/components/user/UserEditEmailModal.vue"),
  },
  data() {
    return {
      customer: {
        lastName: "",
        firstName: "",
        phone: "",
        emailConfirmed: false,
        email: "",
      },
    };
  },
  computed: {
    APPLICATION_MODE() {
      return APPLICATION_MODE;
    },
    creditEnabled: () => creditEnabled(),
  },
  async mounted() {
    await this.fetchCustomer();
  },
  methods: {
    async fetchCustomer() {
      await this.$store.dispatch("profile/fetch");
      this.customer = this.$store.getters["profile/get"];
    },
    async save() {
      try {
        await this.$store.dispatch("profile/save", this.customer);
        this.$buefy.toast.open({
          message: i18n.t("af:profile.message.successful_save"),
          type: "is-success",
        });
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:profile.message.failed_save"),
          type: "is-danger",
        });
      }
    },
    onDelete() {
      const deletionRequested = sessionStorage.getItem(`${this.customer.id}-profile_deletion_requested`);
      if (deletionRequested) {
        this.$buefy.toast.open({
          message: i18n.t("af:profile.message.deletion_already_requested"),
          type: "is-danger",
        });
      } else {
        this.$buefy.dialog.confirm({
          title: i18n.t("af:profile.confirmation.delete.title"),
          message: i18n.t("af:profile.confirmation.delete.subtitle"),
          cancelText: i18n.t("af:profile.confirmation.delete.button.cancel"),
          confirmText: i18n.t("af:profile.confirmation.delete.button.confirm"),
          type: "is-success",
          onConfirm: () => {
            this.customer.gdprDeletion = true;
            sessionStorage.setItem(`${this.customer.id}-profile_deletion_requested`, true);
            this.save();
          },
        });
      }
    },
    onGdpr() {
      const gdprDataRequested = sessionStorage.getItem(`${this.customer.id}-gdpr_data_requested`);
      if (gdprDataRequested) {
        this.$buefy.toast.open({
          message: i18n.t("af:profile.message.gdpr_data_already_requested"),
          type: "is-danger",
        });
      } else {
        this.$buefy.dialog.confirm({
          title: i18n.t("af:profile.confirmation.gdpr.title"),
          message: i18n.t("af:profile.confirmation.gdpr.subtitle"),
          cancelText: i18n.t("af:profile.confirmation.gdpr.button.cancel"),
          confirmText: i18n.t("af:profile.confirmation.gdpr.button.confirm"),
          type: "is-success",
          onConfirm: () => {
            this.customer.gdprDataRequest = true;
            sessionStorage.setItem(`${this.customer.id}-gdpr_data_requested`, true);
            this.save();
          },
        });
      }
    },
    logout() {
      this.$store.dispatch("session/logout");
      this.$store.dispatch("preliminaryAssessment/drop");
      this.$router.push({
        name: "WelcomeLogin",
      });
    },
    async changeApplicationMode(newMode) {
      try {
        await this.$store.dispatch("profile/updateApplicationMode", newMode);
        this.$buefy.toast.open({
          message: i18n.t(
            "af:profile.message.successful_application_mode_save"
          ),
          type: "is-success",
        });
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:profile.message.failed_application_mode_save"),
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.setting-tile {
  background: $grey-2;
  border-radius: 8px;
  padding: 1rem;
}

.confirmation-font {
  font-size: 12px;
}

.mbh {
  .profile-title {
    font-size: 32px !important;
    padding-left: 32px;
  }
}
</style>
